import React, { useReducer, createContext, useContext } from "react";
import PropTypes from "prop-types";

const cacheData = {
  set: (_state) => {
    const state = { ..._state };
    // حذف برخی از متغیر ها از کانتکست
    [].forEach((key) => {
      delete state[key];
    });
    localStorage.setItem("cacheData", JSON.stringify(state));
  },
  get: () => {
    return JSON.parse(localStorage.getItem("cacheData") || "[]");
  },
};

const initialState = cacheData.get() || {};

const stateContext = createContext(undefined);
const dispatchContext = createContext(undefined);

const reducer = (state, action) => {
  let _allState = null;
  switch (action.type) {
    case "setTitle":
      return { ...state, title: action.title };
    default:
      const obj = {};
      let isCache = false;
      Object.keys(action).forEach((key) => {
        if (!["type", "cache"].includes(key)) {
          obj[key] = action[key];
        } else if (key === "cache") {
          isCache = true;
        }
      });
      _allState = {
        ...state,
        ...obj,
      };
      if (isCache) {
        cacheData.set(_allState);
      }
      return _allState;
  }
};

const Provider = ({ children }) => {
  const [state, dispatchState] = useReducer(reducer, initialState);
  return (
    <stateContext.Provider value={state}>
      <dispatchContext.Provider value={dispatchState}>
        {children}
      </dispatchContext.Provider>
    </stateContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
};

const useStateContext = () => {
  return useContext(stateContext);
};
const useDispatchContext = () => {
  return useContext(dispatchContext);
};

export { Provider, useStateContext, useDispatchContext };
