import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#f50057",
    },
    neutral: {
      main: "#c7c7c7",
    },
  },
});

export const Theming = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
