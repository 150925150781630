import React from "react";
import { BrowserRouter } from "react-router-dom";
import CustomRouter from "./CustomRouter";
import { Provider } from "./context";
import { Theming } from "./Theming";
import Main from "./components/layout/main/Main";

function App() {
  return (
    <Theming>
      <Provider>
        <BrowserRouter>
          <Main>
            <CustomRouter />
          </Main>
        </BrowserRouter>
      </Provider>
    </Theming>
  );
}

export default App;
