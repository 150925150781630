import "./Main.scss";
import React from "react";
import PropTypes from "prop-types";
import Header from "../header/Header";

const Main = ({ children }) => {
  return (
    <main className="main">
      <Header />
      {children}
    </main>
  );
};

Main.propTypes = {
  children: PropTypes.any,
};

export default Main;
