import { Link } from "react-router-dom";
import "./Navigation.scss";
const Navigation = () => {
  return (
    <nav className="navigation flex">
      <Link color="secondary" to="/" className="flex">
        <i className="ri-reactjs-line"></i>
        Home
      </Link>
      <Link color="secondary" to="/projects" className="flex">
        <i className="ri-creative-commons-nd-line"></i>
        Projects
      </Link>
      <Link color="secondary" to="/articles" className="flex">
        <i className="ri-article-line"></i>
        Articles
      </Link>
      <Link color="secondary" to="/about" className="flex about">
        <i className="ri-heart-line"></i>
        About
      </Link>
      <Link color="secondary" to="/gallery" className="flex">
        <i className="ri-camera-3-line"></i>
        Gallery
      </Link>
      {/* <Link color="secondary" to="/learn-english" className="flex">
        <i className="ri-english-input"></i>
      </Link> */}
    </nav>
  );
};
export default Navigation;
