import React, { Suspense, lazy } from "react";
import { Route } from "react-router-dom";

const Home = lazy(() => import("./pages/home/Home"));
const Projects = lazy(() => import("./pages/projects/Projects"));
const Articles = lazy(() => import("./pages/articles/Articles"));
const Contact = lazy(() => import("./pages/contact/Contact"));
const About = lazy(() => import("./pages/about/About"));
const Gallery = lazy(() => import("./pages/gallery/Gallery"));
const Details = lazy(() => import("./pages/articles/details/Details"));
const LearnEnglish = lazy(() => import("./pages/learn-english/LearnEnglish"));

const CustomRouter = () => (
  <Suspense fallback={<span></span>}>
    <Route path="/" exact component={Home} />
    <Route path="/projects" exact component={Projects} />
    <Route path="/articles" exact component={Articles} />
    <Route path="/article/:id/:title" exact component={Details} />
    <Route path="/contact" exact component={Contact} />
    <Route path="/about" exact component={About} />
    <Route path="/gallery" exact component={Gallery} />
    <Route path="/learn-english" exact component={LearnEnglish} />
  </Suspense>
);

CustomRouter.defaultName = "CustomRouter";

export default CustomRouter;
